@media only screen and (max-width: 768px), (max-width: 850px) and (orientation: landscape) {
	/* Index */
	.modal-title{
	    line-height: 1.1;
	}
	.line_badge {
	    font-size: 11px;
	}
	.lines_badge {
		font-size: 15px;
	}
	.info_div {
	    top: 4.3rem;
	    max-height: 53%;
	    height: auto;
	    z-index: 13;
	    width: 100%;
	    font-size: 13px;
	}
	.carousel_img, .centerImg {
		max-width: 100vw;
	}
	.title_badge {
		font-size: 14px;
		position: absolute;
		left: -3rem;
		top: 1.5rem;
	}
	.title_badge.minify {
		font-size: 13px;
	}
	.title_badge.minify.super {
		font-size: 11px;
	}
	.carousel-caption-top {
	 	top: 0;
	}
	.carousel-caption {
		padding-top: 40px;
    	padding-bottom: 0;
	}
	.ticket_div {
		top: -2.2rem;
		bottom: 0;
		right: -14%;
		width: 128%;
	}
	.movies_carousel {
		left: 0;
		right: 0;
		padding-top: 0;
	}
	.reparto {
		padding: .5rem 1rem;
		color: #fff;
		margin: .5rem 1.5rem;
		background-color: #000;
		opacity: 0.68;
		font-weight: 780;
		border-radius: .25rem;
		font-size: 14px;
		line-height: 1rem;
	}
	.collapse.show {
	    max-height: 12.5rem;
	}
	.carousel-control-prev{
		left: -2rem;
	}
	.carousel-control-next {
		right: -2rem;
	}
	.boleto > hr {
		margin-top: 0.5rem;
    	margin-bottom: 0.5rem;
	}
	.modal-header {	
	    padding: 0.5rem 1rem;
	}
	/* Mobile Unique */
	.lugarMobile {
		font-size: 9px;
	    position: absolute;
	    right: -3rem;
	    top: 1rem;
		max-width: none;
	}
	.boletoMobile {
		position: absolute;
	    right: -3rem;
	    top: 1.85rem;
	}
	.detalle {
		font-size: 10px;
		margin: 0;
	}
	.boletoDesk, .lugarDesk {
	 	display: none;
	}
	.boletoMobile, .lugarMobile {
	 	display: inline-block;
	}
	.peliculaImg {
		margin: 0 auto;
		padding: 0.5rem;
    	width: 75%;
	}
	.peliculaTitulo {
		position: static;
    	font-size: 15px;
    	max-width: 99%;
	}
	.peliculaTitulo.minify {
		font-size: 14px;
	}
	div#modal_tabs-tabpane-medical .carousel-caption,
	div#modal_tabs-tabpane-market .carousel-caption,
	div#modal_tabs-tabpane-salon .carousel-caption,
	div#modal_tabs-tabpane-repair .carousel-caption,
	div#modal_tabs-tabpane-drink .carousel-caption,
	div#modal_tabs-tabpane-businesses .carousel-caption {
		right: 5%;
		left: 5%;
		padding-top: 0;
	}
	div#modal_tabs-tabpane-medical .carousel-caption-top,
	div#modal_tabs-tabpane-market .carousel-caption-top,
	div#modal_tabs-tabpane-salon .carousel-caption-top,
	div#modal_tabs-tabpane-repair .carousel-caption-top,
	div#modal_tabs-tabpane-drink .carousel-caption-top,
	div#modal_tabs-tabpane-businesses .carousel-caption-top {
		right: -14%;
		left: 15%;
		top: -0.75rem;
	}
	.boleto.opaque-white {
		font-size: 11px;
		margin: 0.5rem 0.25rem;
	}
	.noCarousel {
		max-height: 46vh;
	}
	div.jumbotron.login {
	    padding-top: 3rem;
	}
	.formulario .row {
	  max-height: 8rem;
	}
}

@media only screen and (max-width: 850px) and (orientation: landscape) {
	.title_badge {
		top: 0.75rem;
	}
	.lugarMobile {
		top: 0.25rem;
	}
	.boletoMobile {
		top: 1.15rem;
	}
	.informacion {
		margin: 1rem;
	}
	.peliculaImg {
		width: 52%;
	}
	.carousel_img {
		max-height: 60vh;
		width: auto;
		margin: 0.25rem auto;
	}
	#mobileModal {
		margin: 0.5rem auto;
	}
	.modal-header {
		padding: 0.15rem 0.85rem;
	}
	.modal-body{
		padding: 0.5rem 1rem;
	}
	#mobileModal .modal-footer {
		padding: 0.25rem;
	}
	nav.nav-tabs > a {
		padding: 0.25rem 0.5rem;
	}
	.bizURL {
		padding: .25rem .5rem;
	}
	.noCarousel, .centerImg {
		max-height: 60vh;
	}
}

@media only screen and (max-width: 680px) and (orientation: landscape) {
	.carousel_img, .noCarousel, .centerImg {
		max-height: 52vh;
	}
}

@media only screen and (max-width: 395px) {
	div#modal_tabs-tabpane-medical .carousel-caption-top,
	div#modal_tabs-tabpane-market .carousel-caption-top,
	div#modal_tabs-tabpane-salon .carousel-caption-top,
	div#modal_tabs-tabpane-repair .carousel-caption-top,
	div#modal_tabs-tabpane-drink .carousel-caption-top,
	div#modal_tabs-tabpane-businesses .carousel-caption-top {
		top: -1.25rem;
	}
	
	.TCPrivacy {
	    font-size: 65%;
	    line-height: 1rem;
	}

	.TCPrivacy h2 {
	  	font-size: 1rem;
	}
}

@media only screen and (max-width: 358px), (max-width: 580px) and (orientation: landscape) {
	.title_badge {
	    font-size: 12px;
	    left: -2.5rem;
	}
	.title_badge.minify {
		font-size: 11px;
	}
	.title_badge.minify.super {
		font-size: 9px;
	}
	.peliculaTitulo {
    	font-size: 13px;
	}
	.peliculaTitulo.minify {
		font-size: 12px;
	}
	.lugarMobile {
		right: -2.5rem;
	}
	.boletoMobile {
		right: -2.5rem;
	}
	.modal-title {
		font-size: 22px;
	}
	.line_badge {
	    font-size: 10px;
	}
	.lines_badge {
		font-size: 13px;
	}
	.carousel_img, .noCarousel, .centerImg {
	    max-height: 46vh;
	}
	.accordion > .card > .card-header {
		padding: 0.25rem 0.75rem;
	}
	.info_div {
	    top: 4rem;
	}
	div.jumbotron.login {
		padding: 0.35rem 0;
	}
	div#modal_tabs-tabpane-medical .carousel-caption-top,
	div#modal_tabs-tabpane-market .carousel-caption-top,
	div#modal_tabs-tabpane-salon .carousel-caption-top,
	div#modal_tabs-tabpane-repair .carousel-caption-top,
	div#modal_tabs-tabpane-drink .carousel-caption-top,
	div#modal_tabs-tabpane-businesses .carousel-caption-top {
		top: -1.35rem;
	}
}

@media only screen and (max-width: 580px) and (orientation: landscape) {
	.modal-content {
		width: 85%;
		margin: 0 auto;
	}
	.ticket_div {
	    top: -1.2rem;
	    bottom: -1rem;
	}
	.accordion > .card > .card-header {
		padding: .75rem 1.25rem;
	}
	.collapse.show {
	    max-height: 11.25rem;
	}
	.formulario .row {
	    max-height: 5.5rem;
	}
}

@media only screen and (min-width: 800px) and (orientation: landscape) {
	.carousel_img, .noCarousel, .centerImg {
    	max-height: 56vh;
	}
	div.jumbotron.login {
	    padding-top: 2rem;
	}
}

@media only screen and (min-height: 1000px) {
	.info_div {
		max-height: none;
	}
	div.jumbotron.login {
	    padding-top: 4rem;
	}
}

@media only screen and (min-width: 850px) {
	.collapse.show {
		max-height: 9.5rem;
	}
	.centerImg {
    	max-height: 42vh;
	}
}

@media only screen and (max-height: 400px) and (orientation: landscape) {
	div.jumbotron.formulario {
		padding-top: 1rem;
	}
}