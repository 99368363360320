/* Elements */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Classes */
.carousel_img {
	margin: 1rem auto;
	max-width: 65vw;
  width: 100%;
  height: auto;
}

.carousel-caption-top {
  top: 20px;
  bottom: 0;
}

.carousel-caption-top.movies_carousel {
  top: 15px;
}

.title_badge {
  font-size: 24px;
  cursor: pointer;
}

.lines_badge {
  font-size: 24px;
  cursor: pointer;
}
  
.title_badge.minify {
  font-size: 22px;
}

.title_badge.minify.super {
  font-size: 18px;
}

.ticket_badge {
  padding:0.5rem;
}

.ticket_div {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  height: auto;
  max-height: 305%;
  bottom: 5rem;
  text-align: center;
  overflow-y: auto;
  border: 1px solid rgba(0,0,0,.2);
}

.info_div {
  position: absolute;
  width: 15rem;
  background-color: #ffffff;
  height: 100%;
  top: 0;
  text-align: center;
  overflow-y: auto;
  border: 1px solid rgba(0,0,0,.2);
}

.active_ticket_div {
  color: #343A40;
  background-color: #FFC500;
}

.informacion {
  color: #000000;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: .3rem;
  outline: 0;
  display: inline-block;
  width: 85%;
}

.boleto {
  background-color: white;
  color: #000000;
  padding: 0.5rem;
  margin: 0.5rem;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
  display: inline-block;
} 

.line_badge {
  font-size: 12px;
}

.movies_carousel {
  color: black;
  left: 32%;
  right: 0;
}

.theater_accordion {
  z-index: 12;
}

.accordion_link {
  background-color: #F7F7F7;
  border-bottom: none;
}

.carousel-control-next, .carousel-control-prev {
  z-index: 11;
}

.carousel-control-next {
  right: -3.5em;
}

.carousel-control-prev {
  left: -3.5em;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  /*background-color: #DADADA;*/
  background-color: #929292;
  border-radius: 0.2rem;
}

.collapse.show {
  max-height: 13rem;
  overflow-y: auto;
}

.scroll_card {
  padding-right: 0;
  padding-left: 9px;
}

.card_body {
  padding: 0.75rem;
}

.azulPin {
  color: dodgerblue;
  width: 1.5rem;
  cursor: pointer;
}

.negroPin {
  color: black;
  width: 1.5rem;
  cursor: pointer;
}

.badge-pill {
  margin: 0.15rem;
  overflow: auto;
  max-width: 100%;
}

.boletoDesk, .lugarDesk {
  display: inline-block;
}

.boletoMobile, .lugarMobile {
  display: none;
}

.reparto {
  text-align: left;
  font-size: 21px;
  margin-top: 0.5rem;
}

#modal_tabs-tabpane-default {
  text-align: center;
}

.medical-caption, .local-caption {
  overflow-y: auto;
  max-height: 75%;
}

.noCarousel {
  padding: 1rem;
  text-align: center;
  max-height: 55vh;
  overflow-y: auto;
}

.opaque-white {
  background-color: #FFF;
  opacity: 0.95;
}

.opaque-black {
  background-color: #000;
  opacity: 0.68;
}

.bizURL {
  border: rgba(0,0,0,.1) solid 1px;
  padding: 1rem;
  border-radius: 0.3rem;
  background-color: #007bff;
  color: #fff;
}

.adsURL {
  background-color: grey;
  font-size: 1rem;
}

.adsImg {
  max-width: 100%;
}

.centerImg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom : 0;
  margin: auto;
}

h1.login {
  font-size: 6vw;
}

div.jumbotron.login {
  max-height: 96vh;
  background-color: #fff;
}

div.jumbotron.formulario {
  background-color: transparent;
  position: absolute;
  width: 100%;
  z-index: 2;
}

div.jumbotron.formulario.busqueda {
  position: relative;
}

div.jumbotron.formulario.busqueda button.btn-primary:focus {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff !important;
}

div.jumbotron.formulario.busqueda button.btn-secondary:focus {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d !important;
}

div.jumbotron label {
  margin-bottom: 0.5rem;
}

.formulario .titulo {
  font-weight: bolder;
  font-size: 1.5rem;
}

.formulario .row {
  margin: 0 auto 1rem;
  padding-top: 1rem;
  border: 1px solid #ced4da;
  overflow-y: auto;
  border-radius: .3rem;
}

.formulario p {
  margin-bottom: 0.5rem;
}

.formulario form {
  padding: 1rem;
  border-radius: .3rem;
}

.formulario button {
  margin: 0.25rem;
}

.buttonlogo {
  max-width: 100%;
}

span.footer {
  position: absolute;
  left: 0;
  margin-left: 3.5rem;
}

img.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  max-height: 4rem;
  cursor: pointer;
}

img.BizDiv {
  position: relative;
  margin: 0 auto;
  max-width: 4rem;
  cursor: pointer;
  pointer-events: all;
}

button.logout {
  position: absolute;
  left: 0;
  z-index: 1;
  margin: 1rem;
}

.TCPrivacy {
  overflow-y: auto;
  max-height: 50vh;
}

.TCPrivacy h2 {
  font-weight: bolder;
  margin: 0.5rem;
  text-align: center;
  font-size: 1.3rem;
}

.TCPrivacy p {
  margin-top: 0;
  margin-bottom: 1rem;
  width: 100%;
}

.TCPrivacy a {
  font-weight: bolder;
}

.TCPrivacy ol, .TCPrivacy ul {
  margin-top: 0;
  margin-bottom: 1rem;
  list-style-type: decimal;
  text-indent: 2rem;
  display: inline-block;
}

.TCPrivacy ol li {
  display: list-item;
  list-style-position: inside;
  padding-left: 4px;
  float: left;
  width: 100%;
}

.TCPrivacy ol ol, .TCPrivacy ol ul, .TCPrivacy ul ol, .TCPrivacy ul ul {
  margin-bottom: 0;
  text-indent: 0;
  list-style: disc;
  padding-left: 2rem;
}

.TCPrivacy ol li ol {
  padding-left: 3rem;
}

.TCPrivacy#termsConditions,
.TCPrivacy#privacyPolicy {
  color: #000;
  cursor: pointer;
  position: absolute;
  left: 0;
  z-index: 1;
  margin: 1rem;
  margin-bottom: 1.5rem;
  bottom: 0;
}

.TCPrivacy#privacyPolicy {
  margin-left: 3rem;
}

.TCPrivacy#ayuda > p {
  text-align: center;
  cursor: pointer;
}

.userIcon {
  color: dodgerblue;
  cursor: pointer;
  position: absolute;
  left: 0;
  z-index: 1;
  margin: 4rem 2rem;
  top: 0;
}

.userIcon#helpFAQs {
  margin-top: 7rem;
}

.popover-body h2 {
  font-weight: bolder;
}

span.show_more {
  cursor: pointer;
}

.disable_focus_outline {
    outline: none !important;
}

.disable_focus_outline:focus {
    outline: none !important;
    border-color: #ced4da !important;
    box-shadow: none !important;
}

a#modal_tabs-tab-mas_menos,
a#modal_tabs-tab-mas_menos:hover,
a#modal_tabs-tab-mas_menos:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.ticket_badge.badge-secondary{
  cursor: default;
}

div#modal_tabs-tabpane-medical a.carousel-control-next,
div#modal_tabs-tabpane-medical a.carousel-control-prev,
div#modal_tabs-tabpane-market a.carousel-control-next,
div#modal_tabs-tabpane-market a.carousel-control-prev,
div#modal_tabs-tabpane-salon a.carousel-control-next,
div#modal_tabs-tabpane-salon a.carousel-control-prev,
div#modal_tabs-tabpane-repair a.carousel-control-next,
div#modal_tabs-tabpane-repair a.carousel-control-prev,
div#modal_tabs-tabpane-drink a.carousel-control-next,
div#modal_tabs-tabpane-drink a.carousel-control-prev,
div#modal_tabs-tabpane-businesses a.carousel-control-next,
div#modal_tabs-tabpane-businesses a.carousel-control-prev {
  filter: invert(1);
}

div.noIndicators a.carousel-control-next,
div.noIndicators a.carousel-control-prev {
  display: none;
}

/* Element Children */
.informacion > span {
  font-weight: 1000;
}